/*
 * © 2021 Thoughtworks, Inc.
 */

import { EstimationResult } from '@cloud-carbon-footprint/common'
import { useTheme } from '@material-ui/core/styles'
import { GetApp, PanTool, RotateLeft, ZoomIn } from '@material-ui/icons'
import ApexCharts from 'apexcharts'
import moment, { unitOfTime } from 'moment'
import { equals } from 'ramda'
import React, { FunctionComponent, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { renderToStaticMarkup } from 'react-dom/server'
import { ApexChartProps, DateRange } from '../../../../Types'
import { getMaxOfDataSeries, sumServiceTotals } from '../../../../utils/helpers'
import { CCFTheme, getChartColors } from '../../../../utils/themes'
import CustomTooltip from './CustomTooltip'
import { filterBy, sortByDate } from './helpers'

type LegendToggle = {
  [key: string]: boolean
}

const ApexLineChart: FunctionComponent<ApexChartProps> = ({ data }) => {
  const theme = useTheme() as CCFTheme
  const colors = getChartColors(theme)
  const [blue, aws, gcp, azure, yellow, green, ca, cg, cazure] = [
    colors[0],
    colors[4],
    colors[6],
    colors[2],
    colors[5],
    colors[8],
    colors[3],
    colors[1],
    colors[7],
  ]

  const [dateRange, setDateRange] = React.useState<DateRange>({
    min: null,
    max: null,
  })
  const [chartData, setChartData] = React.useState<EstimationResult[]>([])
  const [defaultRange, setDefaultRange] = React.useState<DateRange>({
    min: null,
    max: null,
  })
  const [toggledSeries, setToggledSeries] = React.useState<LegendToggle[]>([
    { CO2e: true },
    { 'AWS CO2e': false },
    { 'GCP CO2e': false },
    { 'Azure CO2e': false },
    { 'Kilowatt Hours': false },
    { Cost: false },
    { 'AWS Cost': false },
    { 'GCP Cost': false },
    { 'Azure Cost': false },
  ])

  const filteredByZoomRange = filterBy(chartData, dateRange, defaultRange)

  // We need to get the HTML string version of these icons since ApexCharts doesn't take in custom React components.
  // Why, you might ask? Don't ask me, ask ApexCharts.
  const GetAppIconHTML = renderToStaticMarkup(<GetApp />)
  const PanToolIconHTML = renderToStaticMarkup(<PanTool />)
  const RotateLeftIconHTML = renderToStaticMarkup(<RotateLeft />)
  const ZoomInIconHTML = renderToStaticMarkup(<ZoomIn />)

  const cloudEstimationData = sumServiceTotals(filteredByZoomRange)
  const co2SeriesData = removeDuplicatesByAttribute(
    cloudEstimationData.co2Series,
    'x',
  )
  const co2SeriesDataAws = removeDuplicatesByAttribute(
    cloudEstimationData.co2SeriesAws,
    'x',
  )
  const co2SeriesDataGcp = removeDuplicatesByAttribute(
    cloudEstimationData.co2SeriesGcp,
    'x',
  )
  const co2SeriesDataAzure = removeDuplicatesByAttribute(
    cloudEstimationData.co2SeriesAzure,
    'x',
  )
  const kilowattHoursSeriesData = removeDuplicatesByAttribute(
    cloudEstimationData.kilowattHoursSeries,
    'x',
  )
  const costSeriesData = removeDuplicatesByAttribute(
    cloudEstimationData.costSeries,
    'x',
  )
  const costSeriesDataAws = removeDuplicatesByAttribute(
    cloudEstimationData.costSeriesAws,
    'x',
  )
  const costSeriesDataGcp = removeDuplicatesByAttribute(
    cloudEstimationData.costSeriesGcp,
    'x',
  )
  const costSeriesDataAzure = removeDuplicatesByAttribute(
    cloudEstimationData.costSeriesAzure,
    'x',
  )
  const maxCO2e = getMaxOfDataSeries(co2SeriesData)
  const maxKilowattHours = getMaxOfDataSeries(kilowattHoursSeriesData)
  const maxCost = getMaxOfDataSeries(costSeriesData)

  const grouping = data[0]?.groupBy || 'day'
  const dateFormat = {
    day: 'MMM DD, YYYY',
    week: '[Week] w, MMM',
    month: 'MMM YYYY',
    quarter: 'Qo [Quarter] YYYY',
    year: 'YYYY',
  }

  useEffect(() => {
    const newSortedData = sortByDate(data)
    const min = newSortedData[0]?.timestamp
      ? new Date(newSortedData[0]?.timestamp)
      : null
    const endDate = new Date(newSortedData[newSortedData.length - 1]?.timestamp)
    const max = endDate
      ? moment(endDate)
          .add(1, `${grouping}s` as unitOfTime.DurationConstructor)
          .toDate()
      : null
    const newDefaultRange = {
      min,
      max,
    }

    console.log('MAX')
    console.log(max)

    if (!equals(chartData, newSortedData)) setChartData(newSortedData)
    if (
      newDefaultRange.min instanceof Date &&
      newDefaultRange.max instanceof Date
    ) {
      if (!equals(defaultRange, newDefaultRange)) {
        setDateRange(newDefaultRange)
        setDefaultRange(newDefaultRange)
      }
    }
  }, [data])

  useEffect(() => {
    ApexCharts.exec('lineChart', 'updateSeries', [
      {
        name: 'CO2e',
        data: co2SeriesData,
      },
      {
        name: 'AWS CO2e',
        data: co2SeriesDataAws,
      },
      {
        name: 'GCP CO2e',
        data: co2SeriesDataGcp,
      },
      {
        name: 'Azure CO2e',
        data: co2SeriesDataAzure,
      },
      {
        name: 'Kilowatt Hours',
        data: kilowattHoursSeriesData,
      },
      {
        name: 'Cost',
        data: costSeriesData,
      },
      {
        name: 'AWS Cost',
        data: costSeriesDataAws,
      },
      {
        name: 'GCP Cost',
        data: costSeriesDataGcp,
      },
      {
        name: 'Azure Cost',
        data: costSeriesDataAzure,
      },
    ])

    ApexCharts.exec('lineChart', 'updateOptions', [
      {
        xaxis: {
          category: global.labels,
        },
      },
    ])

    toggledSeries.forEach((legendToggle: LegendToggle) => {
      const [seriesKey, toggleValue] = Object.entries(legendToggle)[0]
      toggleValue
        ? ApexCharts.exec('lineChart', 'showSeries', [seriesKey])
        : ApexCharts.exec('lineChart', 'hideSeries', [seriesKey])
    })
  }, [
    co2SeriesData,
    co2SeriesDataAws,
    co2SeriesDataGcp,
    co2SeriesDataAzure,
    kilowattHoursSeriesData,
    costSeriesData,
    costSeriesDataAws,
    costSeriesDataGcp,
    costSeriesDataAzure,
    toggledSeries,
  ])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: any = {
    markers: {
      size: 5,
    },
    chart: {
      events: {
        beforeZoom: (chart: unknown, { xaxis }: { xaxis: DateRange }) => {
          const newFilteredData = filterBy(data, xaxis, defaultRange)

          if (newFilteredData.length >= 2) {
            setDateRange(xaxis)
            return {
              xaxis,
            }
          }
          return {
            dateRange,
          }
        },
        beforeResetZoom: () => {
          setDateRange(defaultRange)
        },
        legendClick: (chart: unknown, seriesIndex: number) => {
          const [seriesKey, toggledValue] = Object.entries(
            toggledSeries[seriesIndex],
          )[0]
          const toggleCheck = toggledSeries.filter(
            (series) => !!Object.values(series)[0],
          )
          const newToggledSeries = [...toggledSeries]
          newToggledSeries[seriesIndex] = {
            [seriesKey]: !toggledValue,
          }
          setToggledSeries(newToggledSeries)
          if (
            toggleCheck.length === 1 &&
            Object.keys(toggleCheck[0])[0] === seriesKey
          )
            setToggledSeries(toggledSeries)
        },
      },
      id: 'lineChart',
      background: theme.palette.background.paper,
      toolbar: {
        tools: {
          download: GetAppIconHTML,
          zoom: ZoomInIconHTML,
          zoomin: false,
          zoomout: false,
          pan: PanToolIconHTML,
          reset: RotateLeftIconHTML,
        },
      },
    },
    colors: [blue, aws, gcp, azure, yellow, green, ca, cg, cazure],
    height: '500px',
    series: [
      {
        name: 'CO2e',
        data: co2SeriesData,
      },
      {
        name: 'AWS CO2e',
        data: co2SeriesDataAws,
      },
      {
        name: 'GCP CO2e',
        data: co2SeriesDataGcp,
      },
      {
        name: 'Azure CO2e',
        data: co2SeriesDataAzure,
      },
      {
        name: 'Kilowatt Hours',
        data: kilowattHoursSeriesData,
      },
      {
        name: 'Cost',
        data: costSeriesData,
      },
      {
        name: 'AWS Cost',
        data: costSeriesDataAws,
      },
      {
        name: 'GCP Cost',
        data: costSeriesDataGcp,
      },
      {
        name: 'Azure Cost',
        data: costSeriesDataAzure,
      },
    ],
    stroke: {
      width: 1,
    },
    theme: {
      mode: theme.palette.type,
    },
    tooltip: {
      shared: true,
      custom: function ({ dataPointIndex }: { dataPointIndex: number }) {
        return renderToStaticMarkup(
          <CustomTooltip
            dataPoint={co2SeriesData[dataPointIndex]}
            grouping={grouping}
          />,
        )
      },
    },
    title: {
      text: 'Cloud Usage',
      offsetY: -8,
      style: {
        fontSize: '24px',
      },
    },
    xaxis: {
      type: 'category',
      tickAmount: 'dataPoints',
      title: {
        text: '',
        offsetY: 18,
        style: {
          fontSize: '15px',
        },
      },
      labels: {
        formatter: function (val) {
          return moment(val).format(dateFormat[grouping])
        },
        style: {
          display: 'contents !important',
        },
      },
    },
    yaxis: [
      {
        max: 1.1 * maxCO2e,
        title: {
          text: '',
          offsetX: -8,
          style: {
            fontSize: '15px',
          },
        },
        tickAmount: 10,
        decimalsInFloat: 2,
        opposite: true,
        axisBorder: {
          show: true,
          color: blue,
        },
        axisTicks: {
          show: false,
        },
        showAlways: false,
      },
      {
        max: 1.1 * maxCO2e,
        title: {
          text: '',
          offsetX: -8,
          style: {
            fontSize: '15px',
          },
        },
        tickAmount: 10,
        decimalsInFloat: 2,
        opposite: true,
        axisBorder: {
          show: true,
          color: aws,
        },
        axisTicks: {
          show: false,
        },
        showAlways: false,
      },
      {
        max: 1.1 * maxCO2e,
        title: {
          text: '',
          offsetX: -8,
          style: {
            fontSize: '15px',
          },
        },
        tickAmount: 10,
        decimalsInFloat: 2,
        opposite: true,
        axisBorder: {
          show: true,
          color: gcp,
        },
        axisTicks: {
          show: false,
        },
        showAlways: false,
      },
      {
        max: 1.1 * maxCO2e,
        title: {
          text: '',
          offsetX: -8,
          style: {
            fontSize: '15px',
          },
        },
        tickAmount: 10,
        decimalsInFloat: 2,
        opposite: true,
        axisBorder: {
          show: true,
          color: azure,
        },
        axisTicks: {
          show: false,
        },
        showAlways: false,
      },
      {
        max: 1.1 * maxKilowattHours,
        title: {
          text: '',
          opposite: -8,
          style: {
            fontSize: '15px',
          },
        },
        tickAmount: 10,
        decimalsInFloat: 2,
        opposite: true,
        axisBorder: {
          show: true,
          color: yellow,
        },
        axisTicks: {
          show: false,
        },
        showAlways: false,
      },
      {
        max: 1.1 * maxCost,
        title: {
          text: '',
          offsetX: 6,
          style: {
            fontSize: '15px',
          },
        },
        tickAmount: 10,
        decimalsInFloat: 2,
        opposite: true,
        axisBorder: {
          show: true,
          color: green,
          offsetX: -5,
        },
        axisTicks: {
          show: false,
        },
        showAlways: false,
      },
      {
        max: 1.1 * maxCost,
        title: {
          text: '',
          offsetX: 6,
          style: {
            fontSize: '15px',
          },
        },
        tickAmount: 10,
        decimalsInFloat: 2,
        opposite: true,
        axisBorder: {
          show: true,
          color: ca,
          offsetX: -5,
        },
        axisTicks: {
          show: false,
        },
        showAlways: false,
      },
      {
        max: 1.1 * maxCost,
        title: {
          text: '',
          offsetX: 6,
          style: {
            fontSize: '15px',
          },
        },
        tickAmount: 10,
        decimalsInFloat: 2,
        opposite: true,
        axisBorder: {
          show: true,
          color: cg,
          offsetX: -5,
        },
        axisTicks: {
          show: false,
        },
        showAlways: false,
      },
      {
        max: 1.1 * maxCost,
        title: {
          text: '',
          offsetX: 6,
          style: {
            fontSize: '15px',
          },
        },
        tickAmount: 10,
        decimalsInFloat: 2,
        opposite: true,
        axisBorder: {
          show: true,
          color: cazure,
          offsetX: -5,
        },
        axisTicks: {
          show: false,
        },
        showAlways: false,
      },
    ],
    grid: {
      padding: {
        bottom: +15,
        right: +20,
      },
    },
  }

  return (
    <Chart
      aria-label="apex-line-chart"
      options={options}
      series={options.series}
      type="line"
      height={options.height}
    />
  )
}

function removeDuplicatesByAttribute(inputArray, attribute) {
  const uniqueValues = new Set()
  const uniqueArray = []
  inputArray?.forEach((element) => {
    const attributeValue = element[attribute]
    if (!uniqueValues.has(attributeValue)) {
      uniqueValues.add(attributeValue)
      uniqueArray.push(element)
    }
  })
  return uniqueArray
}

export default ApexLineChart
